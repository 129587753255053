$('.has-video').click(function () {
	$('.VideoModal .videosrc').attr('src', '');
	$('.VideoModal .videosrc').attr('src', $(this).data('src'));

	$('.VideoModal').modal('show');
})


$('.VideoModal').on('hide.bs.modal', function () {
	$('.VideoModal .videosrc').attr('src', '');
})


$('.applyJob').click(function () {
	$('.fromModal .jobId').val($(this).data('id'));
	$('.fromModal').modal('show');
})

const bootstrapSelect = require('bootstrap-select');

$(`.selectpickerCustom:not(.selectpicker)`).selectpicker();

// 
// 
// 
// 
// 

// function isIE() {
//     var userAgent = navigator.userAgent;
//     return /MSIE|Trident/.test(userAgent);
// }
 
// if (isIE()) {
//     alert("Internet Explorer");
// }
// else {
//     alert("Not Internet Explorer");
// }

// isIE()



// 
// 
// 
// 
// 
