var filterSecondSelect = [];
var allDataVar;

$("select.parentSelect").on('change', function () {
	var selectValue = $(this).val();
	console.log(selectValue);
	let childSelect = $(this).closest('.selectsWrapp').find('select.childSelect')
	specialSecondSelect(selectValue, allData["data"]);
	appenedItems(childSelect, filterSecondSelect)
});

$("select.childSelect").on('change', function () {
	var parentSlectValue = $(this).closest('.selectsWrapp').find('select.parentSelect').val()
	var selectValue = $(this).val();
	let grandChildSelect = $(this).closest('.selectsWrapp').find('select.grandChildSelect')
	specialSecondSelect(parentSlectValue, allData["data"]);
	specialSecondSelect(selectValue, filterSecondSelect);
	appenedItems(grandChildSelect, filterSecondSelect)
});

function appenedItems(element, data) {
	console.log(element);
	console.log(data);
	element.find("option").remove();
	element.append(data && data.map(el => `<option value="${el.value}">${el.label}</option>`));
	element.removeClass('disabledSelect');
	$(element).closest('.selectsWrapp').find('.dropdown.bootstrap-select').removeClass('disabledSelect');
	$('.selectpickerCustom').selectpicker("refresh");
}

function specialSecondSelect(selectValue, allData) {

	for (element of allData) {

		if (element.value == selectValue) {
			filterSecondSelect = element.child;
			break;
		}
	}

}
