

$(".search-btn").click(function(){
	$(".wrapper").addClass("active");
	$(".search-data").fadeIn(500);
	$(".close-btn").fadeIn(500);
	$(".search-data .line").addClass("active");
	setTimeout(function(){
		$(".search-data label").fadeIn(500);
		$(".search-data span").fadeIn(500);
		$("#inputSearch").focus();
	}, 400);
  });
  $(".close-btn").click(function(){
	$(".wrapper").removeClass("active");
	$(".search-btn").fadeIn(800);
	$(".search-data").fadeOut(500);
	$(".close-btn").fadeOut(500);
	$(".search-data .line").removeClass("active");
	$("#inputSearch").val("");
	$(".search-data label").fadeOut(500);
	$(".search-data span").fadeOut(500);
  });