let scrollPos;
var mainHeader = $('.cd-auto-hide-header'),
	belowNavHeroContent = $('.sub-nav-hero'),
	scrolling = false,
	previousTop = 0,
	currentTop = 0,
	scrollDelta = 10,
	scrollOffset = 50;
autohideheader();

function autohideheader() {
	if ((currentTop = previousTop)) {
		mainHeader.removeClass('is-shown');
	}
	$(window).on('scroll', function () {
		let scrollPos = $(document).scrollTop();
		if (scrollPos == 0) {
			mainHeader.removeClass('is-shown');
			mainHeader.removeClass('is-hidden');
		}
		if (!scrolling) {
			scrolling = true;
			(!window.requestAnimationFrame) ? setTimeout(autoHideHeader, 250): requestAnimationFrame(autoHideHeader);
		}
	});

	function autoHideHeader() {
		var currentTop = $(window).scrollTop();
		(belowNavHeroContent.length > 0) ? checkStickyNavigation(currentTop): checkSimpleNavigation(currentTop);
		previousTop = currentTop;
		scrolling = false;
	}

	function checkSimpleNavigation(currentTop) {
		if (previousTop - currentTop > scrollDelta) {
			mainHeader.removeClass('is-hidden');
			mainHeader.addClass('is-shown');
			addStyle();
		} else if (currentTop - previousTop > scrollDelta && currentTop > scrollOffset) {
			mainHeader.addClass('is-hidden');
			mainHeader.removeClass('is-shown');
			addStyle();
		}
	}

	function addStyle() {
		if ((previousTop + currentTop > 500)) {
			mainHeader.addClass('styled');
		} else {
			mainHeader.removeClass('styled');
		}
	}

	function checkStickyNavigation(currentTop) {
		var secondaryNavOffsetTop = mainHeader.height();
		if (previousTop >= currentTop) {
			if (currentTop < secondaryNavOffsetTop) {
				mainHeader.removeClass('is-hidden');
			} else if (previousTop - currentTop > scrollDelta) {
				mainHeader.removeClass('is-hidden');
			}
		} else {
			if (currentTop > secondaryNavOffsetTop + scrollOffset) {
				mainHeader.addClass('is-hidden');
			} else if (currentTop > secondaryNavOffsetTop) {
				mainHeader.removeClass('is-hidden');
			}
		}
	}
}
