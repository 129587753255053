const SnazzyInfoWindow = require('snazzy-info-window');
const dynamics = require('dynamics.js');

var map,
	infoWindow;
var temp_markers = [];
// var pin_class = 'pin_logo_ico';

function get_html_content(data) {
	if ($('.search_location.advanced_search_section').length) {
		// console.log('data is', data)
		var output = '<section class="custom-content">';
		// output += '<h1 class="custom-header d-flex align-items-center">';
		// output += '<i class="fa fa-home"></i>';
		// output += '<span class="infobubble-property-title">' + data.title + '</span>';
		// output += '</h1>';
		output += '<div class="custom-body property-infobubble-content">';
		output += '<div class="property-type-status"></div>';
		output += '<ul class="snazzy_ul">';
		if ((data.locationId != '') && (data.locationId != 'undefined') && (data.locationId != undefined) && (data.locationId !== null) && (data.locationId != null) && (typeof data.locationId !== "undefined")) {


			// console.log('test', temp_locations.length)
			for (var i = 0; i < temp_locations.length; i++) {
				// console.log('ww', temp_locations.length)

				if (data.locationId == temp_locations[i].id) {
					data.loc = temp_locations[i];
					// console.log('ddd', data);
					break;
				}

			}
			if (data.loc) {
				output += '<li class="d-flex list-inline mb-2 align-items-center"> <span>Location: </span>';
				// output += '<img src="' + themeURL + 'images/location.png" class="mr-2 ">';
				output += '<div>' + data.loc.title + '</div>';
				output += '</li>';
			}

		}
		if ((data.startingPrice != '') && (data.startingPrice != 'undefined') && (data.startingPrice != undefined) && (data.startingPrice !== null) && (data.startingPrice != null) && (typeof data.startingPrice !== "undefined")) {

			output += '<li class="d-flex list-inline mb-2 align-items-center"> <span>Starting price: </span>';
			// output += '<img src="' + themeURL + 'images/phone.png" class="mr-2 ">';
			output += '<div> ' + data.startingPrice + '</div>';
			output += '</li>';
		}


		if ((data.email != '') && (data.email != 'undefined') && (data.email != undefined) && (data.email !== null) && (data.email != null) && (typeof data.email !== "undefined")) {
			output += '<li class="d-flex list-inline mb-2 align-items-center"> <i class="fa fa-envelope-open"></i>';
			output += '<a href="mailto:"' + data.email + 'target="_top">';
			output += '<div>' + data.email + '</div>';
			output += '</a> ';
			output += '</li>';
		}
		if ((data.friendlyURL != '') && (data.friendlyURL != 'undefined') && (data.friendlyURL != undefined) && (data.friendlyURL !== null) && (data.friendlyURL != null) && (typeof data.friendlyURL !== "undefined")) {
			output += '<li class="d-flex list-inline mb-2 align-items-center"> ';
			output += '<a href="' + detail_url.replace('__REPLACE__', data.friendlyURL) + '" >';
			output += '<span>  More details ... </san> ';

			output += '</a> ';
			output += '</li>';
		}
		output += '</ul>';
		output += '</div>';
		output += '</section>';

		return output;
	} else {
		// console.log('data is', data)
		var output = '<section class="custom-content">';
		// output += '<h1 class="custom-header d-flex align-items-center">';
		// output += '<i class="fa fa-home"></i>';
		// output += '<span class="infobubble-property-title">' + data.name + '</span>';
		// output += '</h1>';
		output += '<div class="custom-body property-infobubble-content">';
		output += '<div class="property-type-status"></div>';
		output += '<ul class="snazzy_ul">';
		if ((data.address != '') && (data.address != 'undefined') && (data.address != undefined) && (data.address !== null) && (data.address != null) && (typeof data.address !== "undefined")) {


			output += '<li class="d-flex list-inline mb-2 align-items-center">';
			// output += '<img src="' + themeURL + 'images/location.png" class="mr-2 ">';
			output += '<i class="fa fa-home"></i>';
			output += '<div>' + data.address + '</div>';
			output += '</li>';
		}
		if ((data.telephone != '') && (data.telephone != 'undefined') && (data.telephone != undefined) && (data.telephone !== null) && (data.telephone != null) && (typeof data.address !== "undefined")) {

			output += '<li class="d-flex list-inline mb-2 align-items-center"> <span>Tel: </span>';
			// output += '<img src="' + themeURL + 'images/phone.png" class="mr-2 ">';
			output += '<a href="tel:' + data.telephone + '">';
			output += '<div> ' + data.telephone + '</div>';
			output += '</a>';
			output += '</li>';
		}


		if ((data.email != '') && (data.email != 'undefined') && (data.email != undefined) && (data.email !== null) && (data.email != null) && (typeof data.email !== "undefined")) {
			output += '<li class="d-flex list-inline mb-2 align-items-center"> <i class="fa fa-envelope-open"></i>';
			output += '<a href="mailto:"' + data.email + 'target="_top">';
			output += '<div>' + data.email + '</div>';
			output += '</a> ';
			output += '</li>';
		}
		output += '</ul>';
		output += '</div>';
		output += '</section>';


		return output;
	}


};

function get_html_content_nearest(data) {
	var output = '<section class="custom-content">';

	// output += '<h1 class="custom-header justify-content-between d-flex align-items-center">';
	// output += '<i class="fa fa-home"></i>';
	// output += '<span class="infobubble-property-title"> Your nearest store is ' + data.name + '</span>';
	// output += '</h1>';
	output += '<div class="custom-body property-infobubble-content">';
	output += '<div class="property-type-status"></div>';
	output += '<ul class="snazzy_ul">';
	output += '<li class="d-flex list-inline mb-2 align-items-center">';
	output += '<div>' + data.address + '</div>';
	output += '</li>';

	output += '</ul>';
	output += '</div>';
	output += '</section>';

	return output;

}

if ($('#gmap').length) {
	initMap()
}



function initMap() {
	// var $ = require('jquery');




	CustomMarker.prototype = new google.maps.OverlayView();

	function CustomMarker(opts) {
		this.setValues(opts);
	}






	function get_project_marker_logo(store_id) {
		// console.log('store_id', store_id)
		var temp_store = temp_items.find(function (x) {
			return x['id'] == store_id;
		});
		if (temp_store) {
			// return 'Upload-RealEstate-Project-Filename2-' + temp_market['filename2'];
			// pin_class = 'pin_project_logo'
			// return 'http://192.168.1.144/2019/Nile City Towers/Dev/public_html/Upload-RealEstate-Project-Filename2-' + temp_store['filename2'];
			return 'Upload-RealEstate-Project-Filename2-' + temp_store['filename2'];
		}
		// pin_class = 'pin_logo_ico'
		return themeURL + '/images/logo.png';
	};



	CustomMarker.prototype.draw = function () {
		var self = this;
		var div = this.div;
		// var pinLogo = $('storeblock').each().find('.store_img').attr('src');
		if ($('.search_location.advanced_search_section').length) {
			var pinLogo = get_project_marker_logo(this.more_data.id);
		} else {
			//var pinLogo = get_marker_logo(this.more_data.id);

		}


		if (!div) {
			div = this.div = $('' +
				'<div class="marker_wrapp">' +
				'<div class="shadow"></div>' +
				'<div class="pulse"></div>' +
				'<div class="pin-wrap">' +
				'<div class="pin">' +
				'</div>' +
				'<div class="pin_logo">' +
				// '<img class="img-fluid ' + pin_class + '" src="' + pinLogo + '">' +
				'</div>' +
				'</div>' +
				'</div>' +
				'')[0];
			this.pinWrap = this.div.getElementsByClassName('pin-wrap');
			this.pin = this.div.getElementsByClassName('pin');
			this.pinShadow = this.div.getElementsByClassName('shadow');
			this.pin_logo = this.div.getElementsByClassName('pin_logo');
			div.style.position = 'absolute';
			div.style.cursor = 'pointer';
			var panes = this.getPanes();
			panes.overlayImage.appendChild(div);
			google.maps.event.addDomListener(div, "click", function (event) {
				google.maps.event.trigger(self, "click", event);
			});
			// var pin_logo = this.$('.store_img').attr('src','');
			// //console.log(pin_logo);
		}
		var point = this.getProjection().fromLatLngToDivPixel(this.position);


		if (point) {
			div.style.left = point.x + 'px';
			div.style.top = point.y + 'px';
		}
	};
	CustomMarker.prototype.animateDrop = function () {
		dynamics.stop(this.pinWrap);
		dynamics.css(this.pinWrap, {
			'transform': 'scaleY(2) translateY(-' + $('#gmap').outerHeight() + 'px)',
			'opacity': '1',
		});
		dynamics.animate(this.pinWrap, {
			translateY: 0,
			scaleY: 1.0,
		}, {
			type: dynamics.gravity,
			duration: 1800,
		});

		dynamics.stop(this.pin);
		dynamics.css(this.pin, {
			'transform': 'none',
		});
		dynamics.animate(this.pin, {
			scaleY: 0.8
		}, {
			type: dynamics.bounce,
			duration: 1800,
			bounciness: 600,
		})

		dynamics.stop(this.pinShadow);
		dynamics.css(this.pinShadow, {
			'transform': 'scale(0,0)',
		});
		dynamics.animate(this.pinShadow, {
			scale: 1,
		}, {
			type: dynamics.gravity,
			duration: 1800,
		});
	}

	CustomMarker.prototype.animateBounce = function () {
		dynamics.stop(this.pinWrap);
		dynamics.css(this.pinWrap, {
			'transform': 'none',
		});
		dynamics.animate(this.pinWrap, {
			translateY: -30
		}, {
			type: dynamics.forceWithGravity,
			bounciness: 0,
			duration: 500,
			delay: 150,
		});

		dynamics.stop(this.pin);
		dynamics.css(this.pin, {
			'transform': 'none',
		});
		dynamics.animate(this.pinWrap, {
			scaleY: 0.8
		}, {
			type: dynamics.bounce,
			duration: 800,
			bounciness: 0,
		});
		dynamics.animate(this.pinWrap, {
			scaleY: 0.8
		}, {
			type: dynamics.bounce,
			duration: 800,
			bounciness: 600,
			delay: 650,
		});

		dynamics.stop(this.pinShadow);
		dynamics.css(this.pinShadow, {
			'transform': 'none',
		});
		dynamics.animate(this.pinShadow, {
			scale: 0.6,
		}, {
			type: dynamics.forceWithGravity,
			bounciness: 0,
			duration: 500,
			delay: 150,
		});
	}

	CustomMarker.prototype.animateWobble = function () {
		dynamics.stop(this.pinWrap);
		dynamics.css(this.pinWrap, {
			'transform': 'none',
		});
		dynamics.animate(this.pinWrap, {
			rotateZ: -45,
		}, {
			type: dynamics.bounce,
			duration: 1800,
		});

		dynamics.stop(this.pin);
		dynamics.css(this.pin, {
			'transform': 'none',
		});
		dynamics.animate(this.pin, {
			scaleX: 0.8
		}, {
			type: dynamics.bounce,
			duration: 800,
			bounciness: 1800,
		});
	}
	CustomMarker.prototype.getMap = function () {
		return this.map;
	}

	// var $ = require('jquery');
	$(function () {

		var pos = new google.maps.LatLng(default_latitude, default_longitude);
		map = new google.maps.Map(document.getElementById('gmap'), {
			zoom: default_zoom,
			center: pos,
			disableDefaultUI: true,
			zoomControl: false,
			scaleControl: false,
			styles: [{
					"featureType": "administrative",
					"elementType": "geometry.fill",
					"stylers": [{
						"visibility": "off"
					}]
				},
				{
					"featureType": "administrative",
					"elementType": "labels.text",
					"stylers": [{
							"visibility": "on"
						},
						{
							"color": "#8e8e8e"
						}
					]
				},
				{
					"featureType": "administrative",
					"elementType": "labels.text.fill",
					"stylers": [{
						"color": "#7f7f7f"
					}]
				},
				{
					"featureType": "administrative",
					"elementType": "labels.text.stroke",
					"stylers": [{
						"visibility": "off"
					}]
				},
				{
					"featureType": "administrative.country",
					"elementType": "geometry.stroke",
					"stylers": [{
						"color": "#bebebe"
					}]
				},
				{
					"featureType": "administrative.province",
					"elementType": "geometry.stroke",
					"stylers": [{
							"visibility": "on"
						},
						{
							"color": "#cbcbcb"
						},
						{
							"weight": "0.69"
						}
					]
				},
				{
					"featureType": "administrative.locality",
					"elementType": "geometry",
					"stylers": [{
						"visibility": "simplified"
					}]
				},
				{
					"featureType": "landscape",
					"elementType": "all",
					"stylers": [{
						"color": "#e4e4e4"
					}]
				},
				{
					"featureType": "poi",
					"elementType": "all",
					"stylers": [{
						"visibility": "off"
					}]
				},
				{
					"featureType": "road",
					"elementType": "all",
					"stylers": [{
							"saturation": -100
						},
						{
							"lightness": 45
						},
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "geometry.stroke",
					"stylers": [{
						"visibility": "off"
					}]
				},
				{
					"featureType": "road",
					"elementType": "labels",
					"stylers": [{
						"visibility": "off"
					}]
				},
				{
					"featureType": "road.highway",
					"elementType": "all",
					"stylers": [{
							"visibility": "simplified"
						},
						{
							"color": "#dadada"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "labels",
					"stylers": [{
						"visibility": "off"
					}]
				},
				{
					"featureType": "road.highway",
					"elementType": "labels.text",
					"stylers": [{
						"visibility": "simplified"
					}]
				},
				{
					"featureType": "road.arterial",
					"elementType": "all",
					"stylers": [{
						"visibility": "on"
					}]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.text",
					"stylers": [{
						"visibility": "simplified"
					}]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.icon",
					"stylers": [{
						"visibility": "off"
					}]
				},
				{
					"featureType": "road.local",
					"elementType": "all",
					"stylers": [{
						"visibility": "simplified"
					}]
				},
				{
					"featureType": "road.local",
					"elementType": "geometry",
					"stylers": [{
						"color": "#eeeeee"
					}]
				},
				{
					"featureType": "road.local",
					"elementType": "labels.text",
					"stylers": [{
						"visibility": "simplified"
					}]
				},
				{
					"featureType": "transit",
					"elementType": "all",
					"stylers": [{
						"visibility": "off"
					}]
				},
				{
					"featureType": "water",
					"elementType": "all",
					"stylers": [{
							"color": "#cbcbcb"
						},
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "geometry.fill",
					"stylers": [{
						"color": "#d9d9d9"
					}]
				},
				{
					"featureType": "water",
					"elementType": "geometry.stroke",
					"stylers": [{
						"visibility": "off"
					}]
				},
				{
					"featureType": "water",
					"elementType": "labels.text",
					"stylers": [{
						"visibility": "simplified"
					}]
				}
			]

		});

		for (var i in temp_items) {
			var temp_item = temp_items[i];
			var temp_item_position = new google.maps.LatLng(temp_item['latitude'], temp_item['longitude']);
			var temp_marker = new CustomMarker({
				position: temp_item_position,
				map: map,
				store_id: temp_item['id'],
				more_data: temp_item
			});

			infoWindow = new SnazzyInfoWindow({
				marker: temp_marker,
				content: get_html_content(temp_item),
				wrapperClass: 'custom-window row',
				position: temp_item_position,
				offset: {
					top: '0px',
					left: '200px'
				},
				edgeOffset: {
					top: 50,
					right: 30,
					left: 30,
					bottom: 30
				},
				showCloseButton: true,
				border: false,
				closeButtonMarkup: '<button type="button" class="btn custom-close">&#215;</button>',
				callbacks: {
					beforeOpen: function () {},
					open: function () {
						// $(this.getWrapper()).addClass('open');
					},
					afterOpen: function () {
						var that = this;
						var wrapper = $(this.getWrapper());
						wrapper.addClass('active');
						wrapper.find('.custom-close').on('click', function () {
							that.close();
						});
					},
					beforeClose: function () {},
					close: function () {
						this.close();
					},
					afterClose: function () {
						var wrapper = $(this.getWrapper());
						wrapper.find('.custom-close').off();
						wrapper.removeClass('open');
					}
				}
			});

			google.maps.event.addListener(temp_marker, 'click', function (e) {
				this.animateBounce();
				map.setZoom(default_zoom + 3);
				map.panTo(this.position);
				//infoWindow.setContent(get_html_content(this));

			});

			temp_markers.push(temp_marker);
			// console.log('temp_markers_33', temp_markers)
		}



		function altFind(arr, callback) {
			var arrs = [];
			for (var i = 0; i < arr.length; i++) {
				var match = callback(arr[i]);
				//console.log('match', match)

				if (match) {
					arrs.push(arr[i]);

					//return arr[i];
					//break;
				}
			}
			return arrs;
		}

		function gotostore() {
			var item_id = $(this).val();
			gotostoreval(item_id);
		}

		function gotostores() {
			var item_id = $(this).val();
			gotospeceficstores(item_id)

		}

		function gotostoreval(val) {
			var item_id = val;
			var temp_marker = [];
			temp_marker = altFind(temp_markers, function (element) {
				return element.store_id == item_id;
			});
			//console.log('hj', temp_marker);
			if (temp_marker[0]) {
				temp_marker[0].animateBounce();
				map.setZoom(default_zoom + 3);
				map.panTo(temp_marker[0].position);
			}



			// temp_marker.infoWindow.open();
			//     body.animate({ scrollTop: $("#gmap").offset().top - 100 }, 300, function() {
			//         map.setZoom(default_zoom + 3);
			//         map.panTo(temp_marker.position);
			//         //console.log( temp_marker.position.toString() );
			// });
		}


		function gotospeceficstores(val) {
			clearMarkers();

			var item_id = val;
			var temp_marker = [];
			// console.log('temp_markers:', temp_markers);
			if (val == 0) {
				temp_marker = temp_markers
			} else {
				temp_marker = altFind(temp_markers, function (element) {
					return element.more_data.locationId == item_id;
				});
			}
			/* var temp_marker = altFind(temp_markers, function(element) {
			    return element.store_id == item_id;
			}); */
			//console.log('opaa paaaa  new temp', temp_marker);
			var bounds = new google.maps.LatLngBounds();

			for (var i = 0; i < temp_marker.length; i++) {
				if (temp_marker[i]) {
					//console.log('gooooo ' + temp_marker)
					// $(temp_marker[i].div).removeClass('in-view');

					$(temp_marker[i].div).css('display', 'block');

					//console.log('ss', temp_marker[i].more_data.latitude);
					//console.log('longitude', temp_marker[i].more_data.longitude);
					// console.log('ob');
					var positions = new google.maps.LatLng(temp_marker[i].more_data.latitude, temp_marker[i].more_data.longitude);
					// console.log('ob223', positions);
					//   map.panTo(positions);
					temp_marker[i].animateBounce();
					// console.log('ob eeeeeeee');
					var loc = positions;
					bounds.extend(loc);
				}
			}

			map.fitBounds(bounds);
			map.panToBounds(bounds);



		}

		function setMapOnAll() {
			//console.log('temp_markers goooooo ya iii vvv' + temp_markers[0])
			$('.marker_wrapp').css('display', 'none');
			$('.marker_wrapp').removeClass('in-view').addClass('marker-in-view');
		}

		// Removes the markers from the map, but keeps them in the array.
		function clearMarkers() {
			infoWindow.close();
			$(".custom-close").click();
			setMapOnAll();

		}
	});
};
// initMap()







// var $ = require('jquery');
$(".custom-close").click(function () {
	alert('closw me now')

	infoWindow.close();
});



function rad(x) {
	return x * Math.PI / 180;
}

function getNearestFromMarkers(pos) {
	var temp = false;
	var nearestMarker = false;

	for (var i = 0; i < temp_items.length; i++) {
		//    var B = temp_items[i].position;
		// var mlat = parseFloat(temp_items[i].latitude);
		// var mlng = parseFloat(temp_items[i].longitude);


		var B = new google.maps.LatLng(temp_items[i].latitude, temp_items[i].longitude);
		var distance = google.maps.geometry.spherical.computeDistanceBetween(pos, B);


		if (temp === false || temp > distance) {
			temp = distance;
			nearestMarker = temp_items[i];
		}
	}
	//alert('we' + nearestMarker);

	return nearestMarker;
}

function find_closest_marker(pos) {

	var lat = pos.lat;
	var lng = pos.lat;
	var R = 6371; // radius of earth in km
	var distances = [];
	var closest = -1;
	for (i = 0; i < temp_items.length; i++) {
		// alert(temp_items[i].name);
		// alert(temp_items[i].address);
		var mlat = parseFloat(temp_items[i].latitude);
		var mlng = parseFloat(temp_items[i].longitude);
		var dLat = rad(mlat - lat);
		var dLong = rad(mlng - lng);
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(rad(lat)) * Math.cos(rad(lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		distances[i] = d;
		if (closest == -1 || d < distances[closest]) {
			closest = i;
		}
	}
	// alert('we' + temp_items[closest].name);
	//alert(temp_markers[closest]);
	// alert('closeset' + JSON.stringify(temp_markers[closest]));
	return (temp_items[closest]);
}
