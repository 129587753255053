/* eslint-disable */

// Photo Gallery
import Swiper from 'swiper';
let clicked_before = false;
let MSwiper;
window.pushUrlState = function (url) {
	window.history.pushState({
		path: url
	}, '', url);
}

function CloseGallery() {
	$('.photoGalleryModal').modal('hide');

}


function loadmedia(url, index = 0) {
	//$('.photoGalleryModal').find('.in-details').html('');
	$('.photoGalleryModal').find('.gallery').html('');

	$.getJSON(url, function (data) {
		var mylist = "",
			myTitle = "";

		if (typeof data === 'object') {


			var inDetails = ` <div class = "h3 my-1 my-md-3 font-weight-bold  subtitle text-primary italic"> ${  data.media['title'] } </div>
							  <div class = "text mb-3" >${ data.media['desc']} </div>`


			$.each(data.media['gallery'], function (key, media) {
				mylist += ` <div class="swiper-slide"><img class = "img  img-fluid mx-auto" src = ${  media.url } >  </div>`;


			});



			setTimeout(function () {

				// $(thisGalleryWrapp).html('');
				//$('.photoGalleryModal').find('.in-details').html('');
				$('.photoGalleryModal').find('.gallery').html('');


				let data_output = `<div class="gallery has-dynamic-bullets swiper-container">
				                         <div class=" swiper-wrapper align-items-center"></div>
									     <div class = "swiper-pagination     "> </div>
									</div>`

				// $('.photoGalleryModal').find('.slider-data').html('<div class="gallery has-dynamic-bullets swiper-container"><div class=" swiper-wrapper align-items-center"></div><div class="swiper-pagination position-relative mt-4"></div></div>' + '<div class="title-photo-gallery">ddd dd</div>');
				$('.photoGalleryModal').find('.theGallery').html(data_output);

				$('.photoGalleryModal').find('.gallery .swiper-wrapper').html(mylist);
				$('.photoGalleryModal').find('.gallery .swiper-wrapper').html(mylist);
				
				//$('.photoGalleryModal').find('.in-details').html(inDetails);

				$('.photoGalleryModal').removeClass('wait-load');


				//

				var thisSlider = $('.photoGalleryModal').find('.gallery');
				let dynamicBullets = false

				if (thisSlider.hasClass('has-dynamic-bullets')) {
					dynamicBullets = true
				}
				MSwiper = new Swiper(thisSlider, {

					spaceBetween: 0,
					loop: false,
					slidesPerView: 1,
					effect: 'fade',
					speed: 1000,
					preloadImages: false,

					lazy: {
						loadPrevNext: true,
					},

					// autoplay: {
					// 	delay: 2500,
					// },

					pagination: {
						el: $('.photoGalleryModal').find('.swiper-pagination'),
						clickable: true,
						dynamicBullets: dynamicBullets,
						dynamicMainBullets: 7,

					},


					navigation: {
						nextEl: $('.photoGalleryModal').find('.swiper-button-next'),
						prevEl: $('.photoGalleryModal').find('.swiper-button-prev'),
					},




					on: {
						slideChangeTransitionStart: function () {
							//alert('sa')
							//thisSlider.find('.fraction .current').html(MSwiper.activeIndex + 1)





						},
						slideChangeTransitionEnd: function () {






						}
					}



				});


				//MSwiper.slideTo(index, 0)




				var videos = $('[iframe-video]');
				//videos.forEach(function (video) {
				MSwiper.on('slideChangeTransitionStart', function () {

					var iframes = $('.vimeovideo');

					iframes.each(function () {
						var player = $f(this);
						player.api("pause");
					});
					return false;



				})








			}, 200);

		}
	});
};







if ($('.has-events-data').length) {



	$('.has-events-data').each(function () {

		$('.has-events-data').click(function () {
			let ActiveGallery = $(this);


			let DataUrl = $(this).data('url');
			let index = parseInt(0);
			if ($(this).attr('index')) {
				index = parseInt($(this).attr('index'));

			}

			$('.photoGalleryModal').modal('show');
			if ($(this).data('id') && !$(this).hasClass('stopPushState')) {
				let id = $(this).data('id');
				//	if (history.pushState) {
				window.pushUrlState('?' + modalItem + "=" + id)


				//}
			}

			$('.photoGalleryModal').find('.slider-data').html('');
			loadmedia(DataUrl, index);
			$('.photoGalleryModal').addClass('wait-load');
			clicked_before = true;









		});

	});
}


//for opening Modal
let url = '';
let furl = window.location.href;

function GetURLParameter(sParam) {

	var sPageURL = window.location.search.substring(1);

	var sURLVariables = sPageURL.split('&');

	for (var i = 0; i < sURLVariables.length; i++) {

		var sParameterName = sURLVariables[i].split('=');

		if (sParameterName[0] == sParam) {

			return sParameterName[1];

		}

	}

}

$('.photoGalleryModal ,.modal.members').on('hide.bs.modal', function () {
	$('.activeShowinDetails').removeClass('activeShowinDetails');
	if ((history.pushState)) {

		url = "?";

		window.pushUrlState('?')


	}
})
if (typeof modalItem !== 'undefined' && (furl.indexOf('?' + modalItem + '=') != -1)) {
	let active_Item = (GetURLParameter(modalItem));
	if ((active_Item) && ($('*[data-id=' + active_Item + ']').length)) {
		$('*[data-id=' + active_Item + ']').click();

		var n = $('*[data-id=' + active_Item + ']');
		//	n.addClass('active')
		$("html,body").animate({
			scrollTop: n.offset().top + 50
		}, 500);

	}


}


//end modal
