//swipers
import Swiper from 'swiper';
$('.sliderSwiper').each(function () {
	var loop = false;
	if ($(this).find('.swiper-slide').not('.swiper-slide-duplicate').length > 1) {
		loop = true;

	}
	var heroBannerSwiper = new Swiper($(this), {
		// Optional parameters
		// direction: 'horizontal',
		loop: loop,
		// effect: "fade",
		// If we need pagination
		slidesPerView: 1,
		spaceBetween: 0,
		pagination: {
			el: $(this).parent().find('.swiper-pagination'),
			clickable: true,
		},
		speed: 1600,

		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},
		breakpoints: {
			580: {
				speed: 1000,

				autoplay: {
					delay: 3500,
					disableOnInteraction: false,
				},

			},
		}
		// Navigation arrows
		// navigation: {
		// 	nextEl: '.swiper-button-next',
		// 	prevEl: '.swiper-button-prev',
		// },

		// And if we need scrollbar

	});
	heroBannerSwiper.on('slideChange', function () {});



	function initVideo() {
		if (($('.heroBannerSwiper .swiper-slide')).not('.swiper-slide-duplicate').length > 1) {

			var v = document.getElementsByClassName('heroBannerSwiper')[0].getElementsByClassName('swiper-slide-active')[0].getElementsByTagName("video")[0];
			//alert('op');
			if (v) {
				v.addEventListener("canplay", function () {
					console.log(' video started');
					heroBannerSwiper.autoplay.stop();

				}, true);

				v.addEventListener("ended", function () {
					console.log(' video ended');
					heroBannerSwiper.slideNext();
					heroBannerSwiper.autoplay.start();


				}, true);
			}
		}

	}
	heroBannerSwiper.on('transitionStart', function (swiper) {

		var swiperSlideActive = $('.heroBannerSwiper .swiper-slide-active');
		if ($(swiperSlideActive).find('.thevideo').length > 0) {
			heroBannerSwiper.autoplay.stop();
			$(swiperSlideActive).addClass('swiperSlideActive');

			var v = document.getElementsByClassName('heroBannerSwiper')[0].getElementsByClassName('swiper-slide-active')[0].getElementsByTagName("video")[0];
			v.currentTime = 0;

			v.play();

			initVideo();
		}

		// console.log(swiperSlideActive);
	});
	initVideo();



})



//logos
$('.logosSwiper').each(function () {
	let logosNum = 9;
	if ($(this).hasClass('small')) {

		logosNum = 10;
	}
	var mySwiper = new Swiper($(this), {
		// Optional parameters
		direction: 'horizontal',
		loop: false,
		lazy: true,
		speed: 900,

		autoplay: {
			delay: 2500,
			disableOnInteraction: false,
		},
		slidesPerView: logosNum,
		slidesPerGroup: 1,

		spaceBetween: 10,
		centeredSlides: false,

		pagination: {
			el: $(this).parent("").find('.swiper-pagination'),
			clickable: true,
		},
		breakpoints: {
			580: {
				slidesPerView: 3,
				slidesPerGroup: 2,
				spaceBetween: 40,


			},
			// when window width is >= 480px
			992: {
				slidesPerView: 5,

			},
			1400: {
				slidesPerView: logosNum - 2,

			},

		},

	});
})



$('.mySwiper').each(function () {
	var loop = false;
	if ($(this).find('.swiper-slide').not('.swiper-slide-duplicate').length > 1) {
		loop = true;

	}
	var heroBannerSwiper = new Swiper($(this), {
		loop: loop,
		effect: "fade",
		// dynamicBullets: true,
		// direction: 'horizontal',
		// If we need pagination
		slidesPerView: 1,
		spaceBetween: 0,
		pagination: {
			el: $(this).parent().find('.swiper-pagination'),
			clickable: true,
			dynamicBullets: true,
            dynamicMainBullets: 2
		},
		speed: 1000,

		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},
		breakpoints: {
			580: {
				speed: 1000,

				autoplay: {
					delay: 3500,
					disableOnInteraction: false,
				},

			},
		}
		// Navigation arrows
		// navigation: {
		// 	nextEl: '.swiper-button-next',
		// 	prevEl: '.swiper-button-prev',
		// },

		// And if we need scrollbar

	});


})
