const _ = require('underscore');
// var jQuery = require('jquery');
const ajaxForm = require('jquery-form');
const validate = require('validate.js');


window.initValidation = initValidation;

function initValidation() {
    // console.log("hideeee");
    if ($('.form_loader')) {

        $('.form_loader').hide();
    }

    //console.log('exclude');
    // Hook up the form so we can prevent it from being posted
    var forms_array = document.querySelectorAll("form:not(.search__form)");
    for (var f = 0; f < forms_array.length; f++) {
        var form = forms_array[f];
        if ($(form).data() && $(form).data('emoExclude')) {
            //    console.log('form',$(form).data());
            continue;
        }
        /*    if(form.data('emoExclude')){
            }*/
        form.addEventListener("submit", function(ev) {
            ev.preventDefault();
            //Submit Event
            handleFormSubmit(ev.target);
        });
        // Hook up the inputs to validate on the fly
        var inputs = form.querySelectorAll("input, textarea, select");
        for (var i = 0; i < inputs.length; ++i) {
            //Change Event
            //    console.log('inputs.item(i)',inputs.item(i).type);
            if (inputs.item(i).type == 'hidden' || inputs.item(i).type == 'search') {
                continue;
            }
            inputs.item(i).addEventListener("change", function(ev) {
                var current_form = $(ev.target).closest('form');
                ///create constraints
                generateCostraintsForElement(this); //Go to function generate  constrain for element
            });
        }
    }

    function createElementConstrain(ele) {
        var constrainObj = {}; //obj Constrain
        if (ele.emoMinlength) {
            if (constrainObj['length']) {
                constrainObj['length']['minimum'] = ele.emoMinlength;
            } else {
                constrainObj['length'] = {
                    minimum: ele.emoMinlength
                }
            }
            if (validatorLang[current_language].minLength) {
                var res = validatorLang[current_language].minLength.replace("%s", ele.emoMinlength);
                constrainObj['length']['tooShort'] = res;

            }
        }
        if (ele.emoRequired) {
            if (validatorLang[current_language].required) {
                constrainObj['presence'] = {
                    message: validatorLang[current_language].required
                };
            } else {
                constrainObj['presence'] = true;
            }
        }
        if (ele.emoType) {
            if (ele.emoType == 'email') {
                if (validatorLang[current_language].required) {
                    constrainObj['email'] = {
                        message: validatorLang[current_language].emailInvalid
                    };
                } else {
                    constrainObj['email'] = true;
                }
            } else if (ele.emoType == 'phone') {
                constrainObj['format'] = {
                    pattern: "[0-9]+",
                    message: validatorLang[current_language].phone
                };
            }
        }
        if (ele.emoCustom) {
            //  console.log('ele.emoCustom',constraints[ele.emoCustom]);
            if (constraints[ele.emoCustom]) {
                constrainObj = constraints[ele.emoCustom];
            }
            // console.log('ele.cuss',constrainObj);
        }
        return constrainObj;
    }

    function generateCostraintsForElement(currentInput) {
        var _this = currentInput;
        var ele = $(_this).data();
        var constrainObj = createElementConstrain(ele);
        //    console.log(JSON.stringify(constrainObj));
        if (constrainObj) {
            var err = validate.single($(_this).val(), constrainObj, {
                format: "flat"
            });
            showErrorsForInput(_this, err);
            return err;
        }
        //parse Attributes
    }

    function handleFormSubmit(event) {
        //console.log('11event',event);
        //console.log('go event lol',$(event).data('emoWrapper'));
        if ($(event).data() && $(event).data('emoWrapper')) {
            //    console.log('cccc',$(event).find("."+$(event).data('emoWrapper')));
            var item_class = "." + $(event).data('emoWrapper') + " ";
            var inputs = event.querySelectorAll(item_class + " input, " + item_class + " textarea, " + item_class + " select");
        } else {
            var inputs = event.querySelectorAll("input, textarea, select");
        }
        //console.log('inputs',inputs);

        // validate the form aainst the constraints
        // console.log('inputs', inputs);
        var errors = false;
        for (var i = 0; i < inputs.length; ++i) {
            if (inputs.item(i).type == 'hidden' || inputs.item(i).type == 'search') {
                continue;
            }
            //    console.log('inputs.item(i)',inputs.item(i).type);

            //var constrain= constraints[  inputs.item(i).name] ;
            errors = generateCostraintsForElement(inputs.item(i)); //Go to function generate  constrain for element
            //    console.log('errorssss',errors);
            //all_constrain[inputs.item(i).name] = createElementConstrain($(inputs.item(i)).data());
        }
        //console.log('all_constrain',all_constrain);
        //    var errors = validate(event, all_constrain);
        //console.log('errors',errors);
        // then we update the form to reflect the results
        //showErrors(event, errors || {});
        // console.log('errors', errors);
        if (!errors) {
            showSuccess(event);
        }
    }

    function validateForm(e, ele) {
        //    console.log('elrtde',ele);
        //    console.log('sss',$('#'+ele));
        if ($('#' + ele)) {
            var container = document.querySelector("#" + ele);
            var inputs = container.querySelectorAll("input, textarea, select");
            //var inputs = ele.querySelectorAll("input, textarea, select");
            //    console.log('inputsss',inputs);

            // validate the form aainst the constraints
            //console.log(inputs);
            var errors = false;
            for (var i = 0; i < inputs.length; ++i) {
                if (inputs.item(i).type == 'hidden' || inputs.item(i).type == 'search') {
                    continue;
                }
                //    console.log('inputs.item(i)',inputs.item(i).type);

                //var constrain= constraints[  inputs.item(i).name] ;
                errors = generateCostraintsForElement(inputs.item(i)); //Go to function generate  constrain for element
                //        console.log('errorssss',errors);
                //all_constrain[inputs.item(i).name] = createElementConstrain($(inputs.item(i)).data());

            }
            if (!errors) {
                return true;
            } else {
                return false;

            }
            //console.log('all_constrain',all_constrain);
            //    var errors = validate(event, all_constrain);
            //console.log('errors',errors);
            // then we update the form to reflect the results
            //showErrors(event, errors || {});
            //console.log('errors',errors);
        }

    }

    // Updates the inputs with the validation errors
    /*function showErrors(form, errors) {
        // We loop through all the inputs and show the errors for that input
        _.each(form.querySelectorAll("input[name], select[name] ,textarea[name]"), function(input) {
            // Since the errors can be null if no errors were found we need to handle
            // that
            if (input.type != 'hidden' && input.type != 'search') {
                showErrorsForInput(input, errors && errors[input.name]);
            }
        });
    }*/

    // Shows the errors for a specific input
    function showErrorsForInput(input, errors) {
        // This is the root of the input
        //    console.log('input.parentNode',input.parentNode);
        //    console.log('input',input);
        var formGroup = closestParent(input.parentNode, "form-group");
        // Find where the error messages will be insert into
        if (formGroup) {
            // var messages = formGroup.querySelector(".messages");
            if ($(input).data('emoError')) {
                var inputData = $(input).data('emoError');
                //    console.log('ssdf',$(input));
                //    console.log('ghyh',"."+inputData);
                var messages = $(input).closest("form").find("." + inputData + ' .messages')[0];
                //    console.log('oops',messages[0]);
            } else {
                var messages = formGroup.querySelector(".messages");
            }

            // console.log(formGroup, 'formGroup');
            // First we remove any old messages and resets the classes
            resetFormGroup(formGroup);
            // If we have errors
            if (errors) {
                // we first mark the group has having errors
                var eleLabel = $(input).data('emoLabel');
                //console.log('errors',formGroup);
                formGroup.classList.add("has-error");
                // then we append all the errors
                _.each(errors, function(error) {
                    if (eleLabel) {
                        error = eleLabel + ' ' + error;
                    }
                    addError(messages, error);
                });
            } else {
                // otherwise we simply mark it as success
                formGroup.classList.add("has-success");
            }
        }
    }

    // Recusively finds the closest parent that has the specified class
    function closestParent(child, className) {
        if (!child || child == document) {
            return null;
        }
        if (child.classList.contains(className)) {
            return child;
        } else {
            return closestParent(child.parentNode, className);
        }
    }

    function resetFormGroup(formGroup) {
        // Remove the success and error classes
        formGroup.classList.remove("has-error");
        formGroup.classList.remove("has-success");
        // and remove any old messages
        _.each(formGroup.querySelectorAll(".help-block.error"), function(el) {
            el.parentNode.removeChild(el);
        });
    }

    // Adds the specified error with the following markup
    // <p class="help-block error">[message]</p>
    function addError(messages, error) {
        var block = document.createElement("p");
        block.classList.add("help-block");
        block.classList.add("error");
        block.innerText = error;
        if (messages) {
            messages.appendChild(block);
        }
    }

    function ResponseFunction(responseText, statusText, xhr, $form) {
        // remover loader
        if ($('.form_loader')) {
            $('.form_loader').fadeOut();
        }
        // console.log('responseText', responseText);
        if (responseText.status == true) {

            var form = $form[0];
            //console.log('fffff',$(form).data());
            // console.log('formGroup 22', formGroup);

            if ($(form).data() && $(form).data('emoGtag')) {
                //        console.log('dd',$(form).data('emoGtag'));
                if ($.fn.gtag) {
                    //        console.log('dd',$(form).data('emoGtag'));
                    gtag('event', $(form).data('emoGtag'))
                }
            }
            if ($(form).hasClass('feedback')) {
                $('#accordion .card').remove();
                $('#accordion .success_msg').removeClass('d-none').addClass('d-block');

            } else {
                //   $(form).getElementsByID.reset();
                $(form).data('emoGtag');
                $(form).parent().find('.success_msg').removeClass('d-none').addClass('d-block d-flex');
                $(form).html('');
                // Form Check Login

                if ($('form').hasClass('form-check-login')) {
                    // alert('form-check-login');
                    $('body').find('.menu-item-register').addClass('hide-menu-item-register');
                    $('body').find('.menu-item-logout').removeClass('hide-menu-item-register').addClass('show-menu-item-register');
                }
                if ($(form).data("redirect") !== undefined) {
                    //alert('redirect' + $(form).data("redirect"));
                    window.location.href = $(form).data("redirect");


                }

            }

        } else {
            //    var form=$form[0];
            var form = $form[0];
            var errors = responseText.messages;
            for (var i in errors) {
                //  console.log(' i', i);
                var output = '';
                for (var j in errors[i]) {
                    //    console.log('i',i);
                    errors[i][j] = errors[i][j].replace("Value", i);
                    errors[i][j] = errors[i][j].replace("The input", i);
                    errors[i][j] = errors[i][j][0].toUpperCase() + errors[i][j].slice(1);
                    output += "<p class='help-block error'>" + errors[i][j] + "</p>";

                }
                //    var formGroup = closestParent($(form).find("[name='" + i + "']").parentNode, "form-group")
                var formGroup = $(form).find("[name='" + i + "']").parents('.form-group');
                var inputData = $(form).find("[name='" + i + "']").data('emoError');
                if ($(form).find('input[name="captcha"]').length > 0) {
                    grecaptcha.reset();
                }

                // console.log('Mamdouh', inputData);

                if (formGroup) {
                    if (formGroup.hasClass('has-success')) {
                        formGroup.removeClass('has-success');
                    }
                    formGroup.addClass('has-error');
                }

                // if (formGroup && formGroup.hasClass('has-success')) {
                //     formGroup.removeClass('has-success');
                // }
                // formGroup.addClass('has-error');
                if ($(form).hasClass('newsletter_theme_2')) {
                    $(form).find("[name='" + i + "']").parent().parent().find('.messages').html(output);
                } else {
                    if (inputData) {
                        $(form).find("." + inputData + " .messages").html(output);
                    } else {
                        if (formGroup.find(".messages")) {
                            formGroup.find(".messages").html(output);
                        }
                    }
                    // if (formGroup.find(".messages")) {
                    //     formGroup.find(".messages").html(output);
                    // }
                    //$(form).find("[name='" + i + "']").parent().find('.messages').html(output);
                }
            }
            ///femex enough
            // console.log(form);

            var inpForm = $(form).find('.has-error');
            //career2
            // console.log('error', inpForm);
            if (inpForm.length > 0) {
                var first_error = inpForm.eq(0);
                // console.log('first_error', first_error);
                //pane container active show
                $('#career2 .tab-content .tab-pane.container').removeClass('active').removeClass('show');
                first_error.closest('.step_wrap').parent().addClass('active').addClass('show');
                var navId = first_error.closest('.step_wrap').parent().attr('id');
                $('.nav-tabs.steps .nav-link').removeClass('active').removeClass('show');
                $('.nav-tabs.steps a[href="#' + navId + '"]').addClass('active').addClass('show');
                // $('html, body').animate({

                //     scrollTop: $('.nav-tabs.steps').offset().top - $('.navbar').innerHeight()

                // }, 700);

                $('html, body').animate({
                    // scrollTop: (first_error.first().offset().top - $('.navbar').innerHeight())
                    scrollTop: (first_error.first().offset().top)
                }, 500);
            }
            // if ((inpForm.parent().parent().hasClass('tab-pane') && ('.nav-tabs.steps').length)) {
            //     inpForm.parent().parent().addClass('has-error-tab');
            //     var tab = inpForm.parent().parent().attr('id');
            //     $('.nav-tabs.steps a[href="#' + tab + '"]').tab('show');

            //     $('html, body').animate({

            //         scrollTop: $('.nav-tabs.steps').offset().top - $('.navbar').innerHeight()

            //     }, 700);

            // }

            //end tab-pane


        }
    }

    function callValidationFunction() {
        //  validation code for your form HERE
    }

    function showSuccess(form) {
        // add loader
        if ($('.form_loader')) {
            $('.form_loader').fadeIn();
        }
        // console.log('Ajax made it');
        var ele = $(form)[0];
        var options = {
            beforeSubmit: callValidationFunction,
            success: ResponseFunction
        };
        $(ele).ajaxSubmit(options);
        // emptytheinput()
    }

    function emptytheinput(form) {
        var errorsign = $(".has-error:before");
        errorsign.click(function(e) {
            e.preventDefault();
            //    console.log('errorsign');
        });
    }
    emptytheinput()
};

initValidation();





// $('.nav-tabs .nav-link').click(function() {
//     initValidation();
//     alert('initValidation');
// });