/* src/app.js */
/* eslint-disable */

// Styles
// import "../node_modules/bootstrap/scss/bootstrap.scss";
import "./assets/styles/main-ltr.scss";


//Scriptsbootstrap-slider.scss
import 'bootstrap';

import Swiper from 'swiper';



if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    require('./assets/scripts/smooth-scroll.js');
} else {
    require('./assets/scripts/SmoothScroll');

}
//

$(document).ready(() => {
	// require('bootstrap-select/dist/js/bootstrap-select.js');


	require('./assets/scripts/sliders');

	require('./assets/scripts/custom');


	require('./assets/scripts/media-gallery');
	require('./assets/scripts/e-motion-validate');
	require('./assets/scripts/google_map');

	require('./assets/scripts/selectFilter');
	require("./assets/scripts/navBar");
	require('./assets/scripts/auto-hide-header');
	require("./assets/scripts/search-popup");
});
